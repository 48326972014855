import Glide from '@glidejs/glide';

function setSlideStyle(elem) {
    elem.css({
        'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
        'transform' : 'rotate(0) translateY(0) scale(1)'
    });
}

function setSlideLeft(elem) {
    elem.css({
        'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
        'transform' : 'rotate(-3deg) translateY(8%) scale(0.9)'
    });
}

function setSlideRight(elem) {
    elem.css({
        'transition' : 'transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1)',
        'transform' : 'rotate(3deg) translateY(8%) scale(0.9)'
    });
}

function initShowSlider(id) {
    let $currentBlock = $('#' + id).closest('.related-shows');
    let $allShowSlides = $currentBlock.find('glide .glide__slides .glide__slide');
    let $imagesCount =  $currentBlock.find('.glide').data('count');
    let $prevShowSlideShown = 0;
    let slideGap = 48;
    var relatedShowsGlide = new Glide('#' + id, {
        type: 'carousel',
        keyboard: false,
        startAt: 0,
        perView: 3.8,
        gap: slideGap,
        breakpoints: {
            767: { perView: 1.4, gap: slideGap*0.33 },
            1023: { perView: 1.4, gap: slideGap*1.33 },
            1279: { perView: 2.7, gap: slideGap*0.67 },
            1439: { perView: 3.2, gap: slideGap*0.67 },
            1919: { perView: 3.2, gap: slideGap*0.67 },
            3000: { perView: 4.3, gap: slideGap*0.83 },
        },
        autoplay: false,
        animationDuration: 500,
        animationTimingFunc: 'cubic-bezier(0.465, 0.840, 0.440, 1.000)'
    });

    relatedShowsGlide.on(['mount.after'], function() {
        let $activeShow = $currentBlock.find('.glide .glide__slide--active:not(.glide__slide--clone)');
        let $prevShowSlide = $activeShow.prev();
        let $nextShowSlide = $activeShow.next();
        $prevShowSlideShown = $activeShow.data('index');
        if($(window).width() < 1024) {
            $currentBlock.find('.glide .glide__slide--clone').css({
                'transition' : 'none',
                'transform' : 'rotate(0) translateY(0) scale(1)',
            });
            $prevShowSlide.find('.glide-show-overlay').addClass('show');
            $prevShowSlide.css({
                'transition' : 'none',
                'transform' : 'rotate(-3deg) translateY(8%) scale(0.9)'
            });
            $nextShowSlide.find('.glide-show-overlay').addClass('show');
            $nextShowSlide.css({
                'transition' : 'none',
                'transform' : 'rotate(3deg) translateY(8%) scale(0.9)'
            });
        }
    });

    relatedShowsGlide.on('run', function() {
        let $nextActiveShowIndex = relatedShowsGlide.index;
        let $nextActiveShow = $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($nextActiveShowIndex);

        if($(window).width() < 1024) {
            if($prevShowSlideShown == $imagesCount-1 && $nextActiveShowIndex == 0) {
                $currentBlock.find('.glide .glide__slide--clone[data-index="0"]').find('.glide-show-overlay').removeClass('show');
                $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq(0).find('.glide-show-overlay').removeClass('show');
                setSlideStyle($currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq(0));
                $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount-1).next().find('.glide-show-overlay').removeClass('show');
                setSlideStyle($currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount-1).next());
                $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq(0).prev().find('.glide-show-overlay').addClass('show');
                setSlideLeft($currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq(0).prev());
                $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount-1).next().next().find('.glide-show-overlay').addClass('show');
                setSlideRight($currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount-1).next().next());
                $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount - 1).find('.glide-show-overlay').addClass('show');
                setSlideLeft($currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount - 1));
                $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq(1).find('.glide-show-overlay').addClass('show');
                setSlideRight($currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq(1));
            }
            else if($prevShowSlideShown == 0 && $nextActiveShowIndex == $imagesCount-1) {
                $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount - 1).find('.glide-show-overlay').removeClass('show');
                setSlideStyle($currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount - 1));
                $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq(0).prev().find('.glide-show-overlay').removeClass('show');
                setSlideStyle($currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq(0).prev());
                $currentBlock.find('.glide .glide__slide--clone').eq(0).find('.glide-show-overlay').addClass('show');
                setSlideLeft($currentBlock.find('.glide .glide__slide--clone').eq(0));
                $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount - 1).next().find('.glide-show-overlay').addClass('show');
                setSlideRight($currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount - 1).next());
                $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq(0).find('.glide-show-overlay').addClass('show');
                setSlideRight($currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq(0));
                $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount - 2).find('.glide-show-overlay').addClass('show');
                setSlideLeft($currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($imagesCount - 2));
            }
            else if($prevShowSlideShown < $nextActiveShowIndex) {
                let $prevShowSlide = $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($nextActiveShowIndex - 1);
                let $nextShowSlide = $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($nextActiveShowIndex + 1);
                $prevShowSlide.find('.glide-show-overlay').addClass('show');
                setSlideLeft($prevShowSlide);
                $nextShowSlide.find('.glide-show-overlay').addClass('show');
                setSlideRight($nextShowSlide);
                $nextActiveShow.find('.glide-show-overlay').removeClass('show');
                setSlideStyle($nextActiveShow);
                if($prevShowSlideShown == ($imagesCount-2)) {
                    setSlideRight($nextActiveShow.next());
                    $nextActiveShow.next().find('.glide-show-overlay').addClass('show');
                }
            }
            else if ($prevShowSlideShown > $nextActiveShowIndex) {
                let $prevShowSlide = $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($nextActiveShowIndex - 1);
                let $nextShowSlide = $currentBlock.find('.glide .glide__slide:not(.glide__slide--clone)').eq($nextActiveShowIndex + 1);
                $prevShowSlide.find('.glide-show-overlay').addClass('show');
                setSlideLeft($prevShowSlide);
                $nextShowSlide.find('.glide-show-overlay').addClass('show');
                setSlideRight($nextShowSlide);
                $nextActiveShow.find('.glide-show-overlay').removeClass('show');
                setSlideStyle($nextActiveShow);
                if($nextActiveShowIndex == 0) {
                    setSlideLeft($nextActiveShow.prev());
                    $nextActiveShow.prev().find('.glide-show-overlay').addClass('show');
                }
            }
        }
    });

    relatedShowsGlide.on('run.after', function() {
        let $activeShow = $currentBlock.find('.glide .glide__slide--active:not(.glide__slide--clone)');
        $prevShowSlideShown = $activeShow.data('index');
        if(relatedShowsGlide.index > 0 && $('.glide .glide-buttons.prev').length > 0) {
            $currentBlock.find('.glide .glide-buttons.prev').addClass('display-left');
        }
        else if ($currentBlock.find('.glide .glide-buttons.prev').length > 0) {
            $currentBlock.find('.glide .glide-buttons.prev').removeClass('display-left');
        }
    });

    var showImages = document.getElementById(id);
    if ((showImages && window.innerWidth <1024 && showImages.querySelector('.glide__slides').children.length > 1) || (showImages && window.innerWidth >= 1024 && showImages.querySelector('.glide__slides').children.length > 3) ) {
        relatedShowsGlide.mount();

        var glideNavigation = showImages.querySelector('.glide-navigation');

        if (glideNavigation) {
            relatedShowsGlide.on('run', function (e) {
                var items = glideNavigation.querySelectorAll('a');
                items.forEach(function (item) {
                    item.classList.remove('active');
                });
                items[relatedShowsGlide.index].classList.add('active');
            });

            glideNavigation.querySelectorAll('a').forEach(function (link) {
                link.addEventListener('click', function (e) {
                    var elem = this;
                    e.preventDefault();
                    $allShowSlides.each(function(index){
                        $allShowSlides.eq(index).css({
                            'transition' : 'none',
                            'transform' : 'rotate(0) translateY(0)'
                        });
                    });
                    relatedShowsGlide.go('=' + elem.getAttribute('data-id'));
                });
            });
        }
    }
}

window.initShowSlider = initShowSlider;
const $dropdownBlock = $('.list-dropdowns');
const $dropdown = $('.list-dropdowns li .item-top');
const $close = $('.list-dropdowns li .bottom svg');

$dropdown.click(function (e) {
    var $this = $(this);
    $this.siblings('.expandable-content').slideToggle(400);
    if ($this.parent().hasClass('open')) {
        closeDropdown($this);
    }
    else {
        $this.parent().addClass('open');
        $this.siblings('.expandable-content').find('.image').addClass('show');
        $this.find('svg').first().addClass('anim');
    }
});
    
$close.click(function() {
    $(this).parents('.expandable-content').slideUp(400);
    closeDropdown($(this).parent().parent('.expandable-content').siblings('.item-top'));
    $('html, body').animate({
        scrollTop: $('ul.list-items').offset().top - $('header').outerHeight()
    }, 400);
});

function closeDropdown(elem) {
    elem.siblings('.expandable-content').find('.image').removeClass('show');
    elem.parent().css({
        'background-color': 'rgba(0, 0, 0, 0.7)'
    });
    setTimeout(function () {
        elem.parent().removeClass('open');
    }, 400);
    elem.find('svg').last().removeClass('anim');
    setTimeout(function() {
        elem.find('svg').last().removeClass('show');
        elem.find('svg').first().removeClass('hide');
        elem.find('svg').first().removeClass('anim');
    }, 200);
}

$('.list-dropdowns .continue-indicator a').click(function (e) {
    if ($(this).attr('href') === '#') {
        e.preventDefault();

        var $this = $(this);
        var $parent = $this.parents('.fullwidth-image');
        var $next = $parent.next('*');
        let offset;

        if ($(window).width() < 1024) {
            offset = 100;
        }
        else {
            offset = 0;
        }
        if ($next.length > 0) {
            $('html, body').animate({
                scrollTop: $next.offset().top - offset
            }, 400);
        }
    }
});

if($dropdownBlock.length > 0 && $(window).width() >= 1024) {
    const $oddItems =  $('li.dropdown-item.item-odd');
    const $evenItems = $('li.dropdown-item.item-even');
    $oddItems.wrapAll('<div class="odd-items"></div>');
    $evenItems.wrapAll('<div class="even-items"></div>');
}
import Swipe from 'swipejs'

var animationLength = 300; // Change this in _grensesnitt-lightbox.scss as well if changed
var $grensesnittLightboxContainer = $('.gl-container');
var $navPrev = $('.gl-nav-prev > a');
var $navNext = $('.gl-nav-next > a');
var swipe;
var $lightboxes = $('.lightbox');
var $downloadButton = $('.gl-inner .gl-footer a.download-btn');

function getReadableFileSizeString(fileSizeInBytes) {
    var i = -1;
    var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
};

if($(window).width() >= 1024) {
    $lightboxes.click(function (e) {
        e.preventDefault();

        showLightbox();

        if (swipe) {
            swipe.kill();
            swipe = null;
        }

        var $lightboxImages = $(this).closest('.glide__slides').children('li').find('a');
        $lightboxImages.each(function (index, el) {
            var $el = $(el);
            $el.attr('data-index', index);
            $el.attr('data-size', $el.find("img").data("size"));
        });

        addImages($lightboxImages);

       let $firstImageURL = ($('.gl-swipe-wrap div').first().find('img').attr('data-download-url'));
       let $firstImageSize = getReadableFileSizeString(($('.gl-swipe-wrap div').first().find('img').attr('data-size')));
       $downloadButton.attr("href", $firstImageURL);
       const $text = $downloadButton.html()
       $downloadButton.html($text + ' <span class="size">(' + $firstImageSize + ')</span>')

        swipe = new Swipe($('.gl-swipe')[0], {
            draggable: true,
            continuous: true,
            disableScroll: true,
            transitionEnd: function(index, elem) {
                let $elem = $(elem);
                let $currentURL = $elem.find('img').attr('data-download-url');
                let $currentSize = $elem.find("img").attr("data-size")
                $downloadButton.attr("href", $currentURL);
                var $downloadButtonSpan = $('.gl-inner .gl-footer a.download-btn .size');
                $downloadButtonSpan.html(' <span class="size">(' + getReadableFileSizeString($currentSize) + ')</span>');
            },
        });

        $grensesnittLightboxContainer[0].addEventListener('touchmove', function (e) {
            e.preventDefault();
        });

        var index = parseInt($(this).data('index')) || 0;
        swipe.slide(index, 1);
    });

    $('.gl-container .gl-close').click(function (e) {
        e.preventDefault();      
        hideLightbox();
    });

    $navNext.click(function (e) {
        e.preventDefault();
        swipe.next();
    });

    $navPrev.click(function (e) {
        e.preventDefault();
        swipe.prev();
    });

    $('.gl-image').click(function (e) {
        if (e.target.tagName === 'IMG') {
            return;
        }
        hideLightbox();
    });

    function addImages($lightboxImages) {
        var $swipeWrap = $('.gl-swipe-wrap');
        $swipeWrap.html('');
        
        $lightboxImages.each(function (index, el) {
            var $el = $(el);
            var $size = $el.data("size");
            var url = $el.find('img').attr('src');
            var alt = $el.find('img').attr('alt');
            var downloadUrl = el.href;
            $swipeWrap.append('<div><div class="image"><img data-size="' + $size + '" src="' + url + '" alt="' + alt + '" data-download-url="' + downloadUrl + '" /></div></div>');
        })
    }

    function hideLightbox() {
        $grensesnittLightboxContainer.removeClass('fade');
        $('header').removeClass('lightbox-open');

        setTimeout(function () {
            $grensesnittLightboxContainer.removeClass('show');
            $('body').removeClass('no-scroll');
            var $downloadButtonSpan = $('.gl-inner .gl-footer a.download-btn .size'); 
            $downloadButtonSpan.remove();
        }, animationLength);
    }

    function showLightbox() {
        $grensesnittLightboxContainer.addClass('show');

        setTimeout(function () {
            $grensesnittLightboxContainer.addClass('fade');
            $('header').addClass('lightbox-open');

            setTimeout(function () {
                $('body').addClass('no-scroll');
            }, animationLength);
        }, 10);
    }
}

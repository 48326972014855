const $links = $('.page-links .wrapper .page-link');

$links.hover(function() {
    let $this = $(this);
    if($this.find('.info .description').text().length > 0) {
        $this.find('h2').addClass('hide');
    }
    $this.find('.info').removeClass('anim');
    $this.find('.info').addClass('show');
    $this.find('.overlay').addClass('gradient');
}, function() {
    let $this = $(this);
    $this.find('h2').removeClass('hide');
    $this.find('.info').addClass('anim');
    setTimeout(function() {
        $this.find('.info').removeClass('show');
    }, 600);
    $this.find('.overlay').removeClass('gradient');
});
const $showInfo = $('section.show-information');

$('article .continue-indicator a').click(function (e) {
    if ($(this).attr('href') === '#') {
        e.preventDefault();

        var $this = $(this);
        var $parent = $this.parents('.article-hero');
        var $next = $parent.next('*');
        let offset = 100;

        if ($next.length > 0) {
            $('html, body').animate({
                scrollTop: $next.offset().top - offset
            }, 400);
        }
    }
});

function setColumns() {
    if($(window).width() >= 1920) {
        const $column1 = $showInfo.find('ul li.xlarge-column-1');
        const $column2 = $showInfo.find('ul li.xlarge-column-2');
        const $column3 = $showInfo.find('ul li.xlarge-column-3');
        const $column4 = $showInfo.find('ul li.xlarge-column-4');
        $column1.wrapAll('<div class="xlarge-items-column-1"></div>');
        $column2.wrapAll('<div class="xlarge-items-column-2"></div>');
        $column3.wrapAll('<div class="xlarge-items-column-3"></div>');
        $column4.wrapAll('<div class="xlarge-items-column-4"></div>');
    }
    else if($(window).width() >= 1440) {
        const $column1 = $showInfo.find('ul li.item-column-1');
        const $column2 = $showInfo.find('ul li.item-column-2');
        const $column3 = $showInfo.find('ul li.item-column-3');
        $column1.wrapAll('<div class="items-column-1"></div>');
        $column2.wrapAll('<div class="items-column-2"></div>');
        $column3.wrapAll('<div class="items-column-3"></div>');
    }
    else if($(window).width() >= 768) {
        const $oddItems =  $showInfo.find('ul li.item-odd');
        const $evenItems = $showInfo.find('ul li.item-even');
        $oddItems.wrapAll('<div class="odd-items"></div>');
        $evenItems.wrapAll('<div class="even-items"></div>');
    }
}
if($showInfo.length > 0 ) {
    setColumns();

    $(window).resize(function() {
        if($('.xlarge-items-column-1').length > 0) {
            const $column1 = $showInfo.find('ul li.xlarge-column-1');
            const $column2 = $showInfo.find('ul li.xlarge-column-2');
            const $column3 = $showInfo.find('ul li.xlarge-column-3');
            const $column4 = $showInfo.find('ul li.xlarge-column-4');
            $column1.unwrap();
            $column2.unwrap();
            $column3.unwrap();
            $column4.unwrap();
        }
        else if($('.items-column-1').length > 0) {
            const $column1 = $showInfo.find('ul li.item-column-1');
            const $column2 = $showInfo.find('ul li.item-column-2');
            const $column3 = $showInfo.find('ul li.item-column-3');
            $column1.unwrap()
            $column2.unwrap();
            $column3.unwrap();
        }
        else if($('.odd-items').length > 0) {
            const $oddItems =  $showInfo.find('ul li.item-odd');
            const $evenItems = $showInfo.find('ul li.item-even');
            $oddItems.unwrap();
            $evenItems.unwrap();
        }
        setColumns();
    });

    if($('body').hasClass('single-actor')) {
        let $moreButton = $showInfo.find('a.show-more');
        let hiddenShows = $showInfo.find('ul li.hide');

        $moreButton.click(function(e) {
            e.preventDefault();
            hiddenShows.removeClass('hide');
            $moreButton.addClass('hide');
        });
    }
}
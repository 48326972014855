import {CibulCalendar} from './CibulCalendar.js';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import { initHeroSlider } from "./_hero";
import { intersectionObserve } from "./_intersection-observer";

const $activeSelect = $('.show-filter .select-input .active-input');
const $selectOptions = $('.show-filter .select-input .select-options');
const $overlay = $('#filter-overlay');
let $shows, $container, $selectedDates, $block, blockType;
let firstSelect = false;
let $selectedDate = false;
let current_events = [];
let showsFetched = false;

if($('.program').length > 0) {
    $shows = $('.shows-wrapper .tease-show:not(.prev-show)');
    $block = $('.program');
    $container = $('.program .shows-wrapper');
    blockType = 'program';
}
else if ($('.magasin-section.current').length > 0) {
    $shows = $('.magasin-posts .tease-post');
    $block = $('.magasin-section');
    $container = $('.magasin-section .magasin-posts');
}
else if ($('.magasin-section').length > 0) {
    $shows = $('.magasin-posts .tease-magasin_post');
    $block = $('.magasin-section');
    $container = $('.magasin-section .magasin-posts');
}
else if($('.hero').length > 0) {
    $shows = $('.hero .tease-show');
    $block = $('.hero');
    $container = $('.hero .shows-wrapper');
    blockType = 'hero';
    
    $('.hero .glide').css({
        'opacity' : 0
    });
    setTimeout(function() {
        $('.hero .glide').css({
            'opacity' : 1
        });
    }, 50);
}

function getShowTimes($elements) {
    let data = new FormData();
    data.append('action', 'get_show_times');
    $.ajax({
        url: rogalandteater_scripts.ajax_url,
        type: 'POST',
        data: data,
        cache: false,
        processData: false,
        contentType: false,
        error: function (data) {
            console.error(data);
        },
        complete: function (data) {
            let allShows = JSON.parse(data.responseText);
            for(let i=0; i<allShows.length; i++) {
                let event_times = [];
                for(let j=0; j<allShows[i].shows.length; j++) {
                    event_times.push(allShows[i].shows[j].show_time);
                }
                current_events.push({event_no: allShows[i].event_no, times: event_times});
            }
            filterShows($elements);
        }
    });
}

ScrollTrigger.batch($shows, {
    interval: 0.1, // time window (in seconds) for batching to occur. The first callback that occurs (of its type) will start the timer, and when it elapses, any other similar callbacks for other targets will be batched into an array and fed to the callback. Default is 0.1
    batchMax: 4,   // maximum batch size (targets)
    start: "top bottom",
    onEnter: (batch) => {
        gsap.to(batch, {y: 0, autoAlpha: 1, stagger: 0.2, overwrite: true});
        },
    ease: 'power1',
    once: true
});

var showCal = new CibulCalendar(document.getElementById('show-dates'), {
    lang: 'no',
    monthNames: {
      'no': ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember']
    },
    weekDays: {
      'no': ['S', 'M', 'T', 'O', 'T', 'F', 'L']
    },
    navDomContent: {
        prev:'', next: ''
    },
    onSelect: function(selected) {
      $selectedDates = selected;
      let $start = new Date($selectedDates.begin);
      let $end = new Date($selectedDates.end);
      if(+$start === +$end) {
        $('.show-filter .show-date.select-input .active-input').find('span').html($start.toLocaleDateString('no-NO', { day:'numeric', month:'long'}));
    }
      else {
        $('.show-filter .show-date.select-input .active-input').find('span').html($start.toLocaleDateString('no-NO', { day:'numeric', month:'long'}) + ' - ' + $end.toLocaleDateString('no-NO', { day:'numeric', month:'long'}));
    }
    $selectedDate = true;
    if(!showsFetched) {
        getShowTimes($('.show-date .select-options'));
        showsFetched = true;
    }
    else {
        filterShows($('.show-date .select-options'));
    }
    }
});

if($block !== undefined) {
    // Close dropdowns on click outside of dropdown element
    $block.click(function() {
        if($('.show-filter .select-input .active-input').length > 0) {
            $('.show-filter .select-input .active-input').find('a').each(function() {
                let $this = $(this);
                var $target = $(event.target);
                if(!$target.closest('a').length) {
                    if(!$target.parents('.select-input').hasClass('show-date') && !$target.closest('li').hasClass('calnextmonth') && !$target.closest('li').hasClass('calprevmonth')) {
                        $this.removeClass('open');
                        closeDropdown($this);
                        $this.parents('.active-input').siblings('.select-options').slideUp(500);
                    }
                }      
            })
        }
    });
}

$activeSelect.find('a').click(function (e) {
    $selectedDate = false;
    e.preventDefault();
    let $this = $(this);
    if($this.closest('.select-input').hasClass('options-active') && firstSelect == false) {
        firstSelect = true;
    }
    $this.parents('.active-input').siblings('.select-options').slideToggle(500);
    $this.toggleClass('open');
    if ($this.parent().hasClass('options-open')) {
        closeDropdown($this);
        $('body').removeClass('no-scroll');
    }
    else {
        $this.parent().addClass('options-open');
        $this.closest('.select-input').addClass('set-pos active');
        $('.select-input').addClass('hide');
        $this.closest('.select-input').removeClass('hide');
        $overlay.addClass('show anim');
        $('.tease-show').addClass('no-pointer');
        if($(window).width() < 768 && !$this.closest('.select-input').hasClass('show-year')) {
            $('body').addClass('no-scroll');
            $('html, body').animate({
                scrollTop: $this.parent().offset().top - $('header').outerHeight()
            }, 400);
        }
    }
});

$selectOptions.find('a').click(function (e) {
    e.preventDefault();
    let $this = $(this);

    if($this.data('slug') == 'andre-arrangementer' && $(window).width() < 768) {
        $this.parents('.select-options').siblings('.active-input').find('span').html('HVA SKJER');
    }
    else {
        $this.parents('.select-options').siblings('.active-input').find('span').html($this.html());
    }
    if($this.parents('.select-input').hasClass('show-year')) {
        if($this.parent('li').hasClass('selected')) {
            if($('.select-input.show-year .select-options li.selected').length == 1) {
                $this.parents('.select-options').siblings('.active-input').find('span').html('ÅRSTALL');
            }
            $this.parent('li').removeClass('selected')
        }
        else {
            $this.parent('li').addClass('selected');
        }
        filterShows($this);
    }
    else {
        $this.parents('.select-options').find('li.selected').removeClass('selected');
        $this.parent('li').addClass('selected');
        $this.parents('.select-input').find('.active-input').children('a').removeClass('open');
        closeDropdown($this);
        $this.parents('.select-input').find('.select-options').slideUp(500);
        if(!showsFetched && (blockType == 'hero' || blockType == 'program')) {
            getShowTimes($this);
            showsFetched = true;
        }
        else {
            filterShows($this);
        }
    }
});

function closeDropdown(elem) {
    setTimeout(function () {
        $overlay.addClass('fade-out');
    }, 300);
    setTimeout(function () {
        $overlay.removeClass('anim show fade-out');
    }, 800);
    setTimeout(function () {
        elem.closest('.select-input').removeClass('active');
        elem.parents('.select-input').find('.active-input').removeClass('options-open');
    }, 500);
    setTimeout(function(){
        $('.select-input').removeClass('hide');
        elem.closest('.select-input').removeClass('set-pos');
    }, 600);
    $('.tease-show').removeClass('no-pointer');
    $('body').removeClass('no-scroll');
}

function filterShows(elem) {
    elem.closest('.select-input').addClass("options-active");
    let $selectedFilters = [];
    let $selectActive = $('.show-filter .select-input.options-active');

    if ($selectActive.length > 0 && ($('.hero').length > 0 || $('.program').length > 0)) {
        let startDate, endDate;
        let $activeTag = $('.show-filter .show-category').find('li.selected a').data('slug');
        if($selectedDates != null) {
            startDate = $selectedDates.begin.toLocaleDateString('no-NO', { day:'numeric', month:'numeric', year: 'numeric' });
            endDate = $selectedDates.end.toLocaleDateString('no-NO', { day:'numeric', month:'numeric', year: 'numeric' });
        }
        else {
            startDate = '';
            endDate = '';
        }
        getShows($activeTag, startDate, endDate, blockType, JSON.stringify(current_events));
    }
    else if ($selectActive.hasClass('show-year')) {
        let $activeSelections = $selectActive.find('li.selected a');
        $activeSelections.each(function(selectIndex) {
            let $activeTag = $activeSelections.eq(selectIndex).data('slug');
            $selectedFilters.push($activeTag);
        });
    }
    let $tags = [];
    for (let i = 0; i < $shows.length; i++) {
        for (let j = 0; j < $selectedFilters.length; j++) {
            if (!$.isArray($selectedFilters[j])) {
                if ($selectedFilters[j] != 'all') {
                    let $match = $.inArray($selectedFilters[j], $tags);
                    if ($match === -1) {
                        $tags.push($selectedFilters[j]);
                    }
                }
            }
        }
    }

    let $showsClasses = 'null';
    for (let j = 0; j < $tags.length; j++) {
        $showsClasses = $tags.join('.');
    }

    if($('.show-filter .select-input.show-year').length > 0) {
        if($selectedFilters.length > 0) {
            $shows.removeClass('show');
            $('article.tease:not(.prev-show)').hide();
            $shows.each(function(index) {
                for (let j = 0; j < $selectedFilters.length; j++) {
                    if($shows.eq(index).hasClass($selectedFilters[j])) {
                        $shows.eq(index).show();
                        $shows.eq(index).addClass('show');
                    }
                }
            });
        }
        else {
            $shows.addClass('show');
            $('article.tease:not(.prev-show)').show();
        }
    }
    else {
        if ($showsClasses != 'null') {
            $shows.removeClass('show');
            $('article.tease:not(.prev-show)').hide();
            $('article.tease.' + $showsClasses).addClass('show');
            $('article.tease.' + $showsClasses).show();
        }
        else {
            $('article.tease').show();
            $('article.tease').addClass('show');
        }
    }
    gsap.to($shows, {y: 0, autoAlpha: 1, stagger: 0, overwrite: true});
}

let textDisplayed = false;
function resultsCheck() {
    if($block.find('.tease').length == 0 && !textDisplayed) {
        $container.append('<h3 class="no-shows-info">Ingen resultater</h3>');
        textDisplayed = true;
    }
    else if ($block.find('.tease').length > 0 && textDisplayed) {
        $container.find('.no-shows-info').remove();
        textDisplayed = false;
    }
}

$('.program .show-filter #show-dates').click(function() {
    $('.ccal .calbody li').removeClass('first-selected last-selected only-selected');
    $('.ccal .calbody li.selected').first().addClass('first-selected');
    $('.ccal .calbody li.selected').last().addClass('last-selected');
    if($('.ccal .calbody li.selected').first().is($('.ccal .calbody li.selected').last())) {
        $('.ccal .calbody li.selected').first().removeClass('first-selected last-selected');
        $('.ccal .calbody li.selected').first().addClass('only-selected');
    }
    $('.ccal .calbody li.calnext').last().removeClass('first-selected last-selected');
    $('.ccal .calbody li.calprev').first().removeClass('first-selected last-selected');
});



function getShows(category, dateFrom, dateTo, blockType, current_events) {
    let data = new FormData();
    data.append('action', 'get_shows');
    data.append('category', category);
    data.append('date_from', dateFrom);
    data.append('date_to', dateTo);
    data.append('block_type', blockType);
    data.append('current_events', current_events);

    $.ajax({
        url: rogalandteater_scripts.ajax_url,
        type: 'POST',
        data: data,
        cache: false,
        processData: false,
        contentType: false,
        error: function (data) {
            console.error(data);
        },
        complete: function (data) {
            $container[0].innerHTML = '';
            $container[0].innerHTML = data.responseText;
            initHeroSlider($('.hero .shows-wrapper .glide').data('id'));
            textDisplayed = false;
            resultsCheck();
            if(blockType == 'program') {
                $('.tease-show').each(function(index) {
                    $('.tease-show').eq(index).css({
                        'transition': 'none',
                        'transform': 'translate(0px, 0px)',
                        'visibility': 'inherit',
                        'opacity': 1
                    });
                });
            }
            else if(blockType == 'hero') {
                let showWrapper = document.querySelector('.hero .shows-wrapper');

                intersectionObserve(showWrapper, 0, (element, intersectionRatio) => {
                    if (intersectionRatio == 1) {
                        if($('.hero .glide .glide__track li').length > 3) {
                            $('.hero .shows-wrapper .glide .glide-buttons').addClass('show');
                        }
                    }
                    else {
                        $('.hero .shows-wrapper .glide .glide-buttons').removeClass('show');
                    }
                });
            }
        }
    });
}
let $showsArchive = $('.blog .content-wrapper');
let $years = $showsArchive.find('.years li a');
let $shows = $showsArchive.find('article.tease-show');
if ($showsArchive.length > 0) {
    $years.click(function(e) {
        e.preventDefault();
        $showsArchive.find('.years li').removeClass('active');
        let $year = $(this).data('slug');
        $(this).parent('li').addClass('active');
        $shows.removeClass('show');
        $shows.each(function(index) {
            if($shows.eq(index).hasClass($year)) {
                $shows.eq(index).addClass('show');
            }
        });
    });
}